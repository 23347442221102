<template>
  <div>
    <NavBar />
    <div class="about">
      <div class="card-container">
        <div class="card-body">
          <!-- Banner Section -->
          <div class="banner_holder">
            <div class="banner-home">
              <div class="banner-info">
                <h1>
                  Transforming <br />
                  Tomorrow, Today
                </h1>
                <p>
                  Empowering businesses and institutions with cutting-edge
                  software
                  <br />
                  solutions, crafted precisely for your unique challenges.
                </p>
              </div>
              <div class="banner_btns">
                <button type="button" class="btn_contact">Contact Us</button>
                <button type="button" class="btn_explore">Explore</button>
              </div>
              <div class="banner_right1">
                <img src="@/assets/home/react.png" alt="" />
              </div>
              <div class="banner_right2">
                <img src="@/assets/home/js.png" alt="" />
              </div>
              <div class="banner_right3">
                <img src="@/assets/home/vue.png" alt="" />
              </div>
              <div class="banner_right4">
                <img src="@/assets/home/flutter.png" alt="" />
              </div>
              <div class="banner_right5">
                <img src="@/assets/home/laravel.png" alt="" />
              </div>
              <div class="banner_right6">
                <img src="@/assets/home/c_shap.png" alt="" />
              </div>
              <div class="banner_right7">
                <img src="@/assets/home/node.png" alt="" />
              </div>
              <div class="banner_right8">
                <img src="@/assets/home/html.png" alt="" />
              </div>
              <div class="banner_right9">
                <img src="@/assets/home/ts.png" alt="" />
              </div>
            </div>
          </div>

          <!-- Line Divider -->
          <div class="linner">
            <hr />
          </div>

          <!-- Our Best Project Section -->
          <div class="banking-services">
            <div class="bast-project">
              <div class="see-title">
                <h2>Our Best Project</h2>
                <a href=""
                  ><img src="@/assets/home/icon_enter.svg" alt="" />See All
                  Service</a
                >
              </div>
            </div>

            <!-- Services Section -->
            <div class="services">
              <div class="service">
                <div class="service-content">
                  <div class="icon">
                    <img
                      src="../assets/home/icon-1.svg"
                      alt="Mobile Banking Icon"
                    />
                  </div>
                  <h3>Delivery Management System</h3>
                  <p>
                    is a software tool that streamlines the delivery process by
                    managing orders, optimizing routes, and providing real-time
                    tracking.
                  </p>
                  <a href=""
                    ><img src="../assets/home/Frame 13.svg" alt=""
                  /></a>
                </div>
              </div>
              <div class="service">
                <div class="service-content">
                  <div class="icon">
                    <img
                      src="../assets/home/icon-2.svg"
                      alt="Saving Account Icon"
                    />
                  </div>
                  <h3>HR Management System</h3>
                  <p>
                    is a software solution that automates and streamlines
                    various human resource functions. It helps manage employee
                    data, recruitment, payroll, performance evaluations, and
                    benefits administration.
                  </p>
                  <a href=""
                    ><img src="../assets/home/Frame 13.svg" alt=""
                  /></a>
                </div>
              </div>
              <div class="service">
                <div class="service-content">
                  <div class="icon">
                    <img src="../assets/home/icon-3.svg" alt="Deposit Icon" />
                  </div>
                  <h3>Loan Management System</h3>
                  <p>
                    is a software solution designed to manage the lifecycle of
                    loans from origination to repayment. It automates processes
                    such as application processing, credit assessment,
                    disbursement, and collections.
                  </p>
                  <a href=""
                    ><img src="../assets/home/Frame 13.svg" alt=""
                  /></a>
                </div>
              </div>
              <div class="service">
                <div class="service-content">
                  <div class="icon">
                    <img
                      src="../assets/home/icon-4.svg"
                      alt="Business Loans Icon"
                    />
                  </div>
                  <h3>School Management System</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <a href=""
                    ><img src="../assets/home/Frame 13.svg" alt=""
                  /></a>
                </div>
              </div>
            </div>
          </div>

          <!-- Section Container -->
          <div class="section-container">
            <h1 class="section-title">
              We enable our clients to accomplish and enjoy the value of
              operational excellence using our innovative data-driven
              technological solutions.
            </h1>
            <div class="row">
              <div class="col-md-4 feature">
                <img src="../assets/home/Group 3.svg" alt="Download App" />
                <h3 class="feature-title">Security System</h3>
                <p class="feature-description">
                  With increasing digitization, come the risk of data and
                  digital trust. Our system solutions have passed rigorous tests
                  by the security team at our bank partners. Our dynamic
                  Research & Development team is also progressively exploring
                  and updating the security measures in our coding as well as
                  infrastructure configuration.
                </p>
              </div>

              <!-- Second Feature -->
              <div class="col-md-4 feature">
                <img
                  src="../assets/home/Group 3 (1).svg"
                  alt="Create Your Account"
                />
                <h3 class="feature-title">Leveraging Technologies</h3>
                <p class="feature-description">
                  With our diversified skills and experiences in both
                  open-source and proprietary technologies such as WebAssembly,
                  C++, C#, PHP, Java, Python, and modern database technologies,
                  we develop our solutions by combining the best capabilities of
                  several computer languages for optimal efficiency and
                  performance.
                </p>
              </div>

              <!-- Third Feature -->
              <div class="col-md-4 feature">
                <img src="../assets/home/Group 3 (2).svg" alt="Start Banking" />
                <h3 class="feature-title">Data Collection & Analysis</h3>
                <p class="feature-description">
                  Given our team’s professional backgrounds, data science
                  techniques are our core competency and we are poised to offer
                  heavy data-oriented business solutions and services.
                </p>
              </div>
            </div>
          </div>

          <!-- Lasted News and Footer -->
          <LastedNews />
          <FooTer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooTer from "@/components/FooTer.vue";
import LastedNews from "@/components/home-page/LastedNews.vue";

export default {
  name: "HomeView",
  components: {
    NavBar,
    FooTer,
    LastedNews,
  },
};
</script>

<style scoped>
/* Global Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.about {
  width: 100%;
  padding: 0;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f7f5;
  padding: 20px;
}

/* Banner Styles */
.banner-home {
  display: flex;
  position: relative;
  flex-direction: column;
  background-image: url("@/assets/home/banner.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  justify-self: center;
  height: 80vh;
  border-radius: 20px;
  color: white;
  padding: 30px;
  margin-top: 100px;
  margin-left: 50px;
  margin-right: 50px;
}
.banner_right1,
.banner_right2,
.banner_right3,
.banner_right4,
.banner_right5,
.banner_right6,
.banner_right7,
.banner_right8,
.banner_right9 {
  display: flex;
  gap: 2rem;
  position: absolute;
}
.banner_right1 {
  bottom: 0;
  right: 10%;
  bottom: 20%;
}
.banner_right2 {
  bottom: 0;
  right: 8%;
  bottom: 33%;
}
.banner_right3 {
  bottom: 0;
  right: 12%;
  bottom: 45%;
  background-color: #0f58a8;
  border-radius: 10px;
}
.banner_right4 {
  bottom: 0;
  right: 35%;
  bottom: 20%;
}
.banner_right5 {
  bottom: 0;
  right: 37%;
  bottom: 33%;
  background-color: #0f58a8;
  border-radius: 10px;
}
.banner_right6 {
  bottom: 0;
  right: 34%;
  bottom: 45%;
}
.banner_right7 {
  bottom: 0;
  right: 14%;
  bottom: 8%;
}
.banner_right8 {
  bottom: 0;
  right: 22%;
  bottom: 3%;
}
.banner_right9 {
  bottom: 0;
  right: 30%;
  bottom: 8%;
}
.banner_right1 img,
.banner_right2 img,
.banner_right3 img,
.banner_right4 img,
.banner_right5 img,
.banner_right6 img,
.banner_right7 img,
.banner_right8 img,
.banner_right9 img {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}
.banner-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5%;
  margin-bottom: 40px;
  margin-top: 90px;
}

.banner-info h1 {
  font-size: 4vw;
}

.banner-info p {
  margin-top: 20px;
  font-size: 1.5vw;
}

.banner_btns {
  display: flex;
  gap: 1rem;
  margin-left: 5%;
}

.btn_contact,
.btn_explore {
  border-radius: 32px;
  padding: 12px 24px;
  background-color: #0f58a8;
  border: none;
  color: white;
  font-size: 1.5vw;
  cursor: pointer;
}

.btn_explore {
  background-color: transparent;
  border: 1px solid #0f58a8;
}

.btn_contact:hover,
.btn_explore:hover {
  background-color: #0b243b;
}
.btn_contact:focus,
.btn_explore:focus {
  outline: none;
  box-shadow: 0 0 5px 0 rgba(239, 239, 239, 0.636);
}
/* Divider Line */
.linner {
  margin: 40px 60px;
}

/* Services Section */
.banking-services {
  display: flex;
  width: 90%;
  margin: 50px;
  justify-content: space-between;
}

.bast-project {
  margin-bottom: 40px;
}

.bast-project h2 {
  font-size: 3vw;
}
.see-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.see-title h2,
.service-content h3 {
  color: #0f58a8;
}
.see-title a {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.services {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
}

.service {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  flex: 1 1 calc(40% - 10px);
  box-sizing: border-box;
  transition: transform 0.3s ease;
}

.service:hover {
  transform: translateY(-10px);
}

.icon img {
  margin-bottom: 15px;
}

.service h3 {
  font-size: 2vw;
  margin-bottom: 15px;
}

.service p {
  font-size: 1.2vw;
}

/* Section Container */
.section-container {
  text-align: center;
  padding: 40px;
  background: linear-gradient(180deg, #000101 -37.99%, #0f58a8 129.58%);
  color: white;
  border-radius: 32px;
  margin: 50px 5%;
}

.section-title {
  font-size: 2.5vw;
  margin-bottom: 50px;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.feature {
  text-align: center;
  flex: 1;
}

.feature img {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.feature-title {
  font-size: 2vw;
  margin-bottom: 15px;
}

.feature-description {
  font-size: 1.2vw;
}

/* Responsive Styles */
@media (min-width: 768px) and (max-width: 1024px) {
  .banner-home {
    height: 30vh;
    width: 95%;
    margin-left: 20px;
    margin-top: 70px;
  }
  .banner_right1 img,
  .banner_right2 img,
  .banner_right3 img,
  .banner_right4 img,
  .banner_right5 img,
  .banner_right6 img,
  .banner_right7 img,
  .banner_right8 img,
  .banner_right9 img {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    border: 1px solid rgb(17, 81, 145);
  }
  .banner-info h1 {
    margin-top: -90px;
    font-size: 24px;
  }
  .section-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    text-align: center;
    padding: 40px;
    background: linear-gradient(180deg, #000101 -37.99%, #0f58a8 129.58%);
    color: white;
    border-radius: 32px;
    margin-left: 20px;
  }
  .icon img {
    width: 40px;
    height: auto;
    margin-bottom: 10px;
  }
  .section-title {
    margin-top: 0px;
    font-size: 24px;
  }
  .banner-info p {
    font-size: 14px;
    width: 50%;
    margin-top: 0px;
  }
  .btn_contact,
  .btn_explore {
    width: 20%;
    margin-top: -30px;
  }

  .banking-services {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-left: 20px;
    justify-content: space-between;
  }
  .see-title a img {
    width: 5%;
    height: auto;
  }
  .feature-title {
    margin-left: 35px;
    font-size: 18px;
    margin-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    height: 50px;
    text-align: left;
  }
  .feature-description {
    display: flex;
    font-size: 14px;
    margin-left: 35px;
    height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    margin-top: 0px;
    text-align: left;
    width: 90%;
  }
  .service-content {
    font-size: 14px;
    margin-left: 35px;
    height: 180px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    margin-top: 0px;
    text-align: left;
    width: 90%;
  }
}

@media (max-width: 767px) and (min-width: 428px) {
  .banner_holder {
    margin: 0;
    width: 100%;
    margin-left: -50px;
  }
  .banner-home {
    display: flex;
    height: 20vh;
    width: 100%;
    margin-top: 50px;
  }
  .banner_right1 img,
  .banner_right2 img,
  .banner_right3 img,
  .banner_right4 img,
  .banner_right5 img,
  .banner_right6 img,
  .banner_right7 img,
  .banner_right8 img,
  .banner_right9 img {
    display: none;
  }
  .banner-info h1 {
    font-size: 13px;
    margin-top: -110px;
  }
  .banner-info p {
    font-size: 10px;
    height: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    width: 55%;
    margin-top: 0px;
  }
  .banner_btns {
    display: flex;
    margin-top: -50px;
  }
  .btn_contact,
  .btn_explore {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    margin-bottom: 50px;
    padding: 2px;
  }

  .banking-services {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0px;
    justify-content: space-between;
  }
  .see-title {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: 0px;
    margin-right: 0;
    gap: 1rem;
  }
  .see-title h2 {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .see-title a {
    margin-right: 0;
    margin-left: 0;
    font-size: 10px;
  }
  .see-title a img {
    width: 8%;
    height: auto;
  }
  .services {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0px;
  }
  .section-container,
  .row {
    display: flex;
    width: 100%;
    margin-left: 0px;
    flex-direction: column;
  }
  .icon img {
    width: 40px;
  }
  .service-content h3 {
    font-size: 18px;
  }
  .service-content p {
    font-size: 16px;
  }
  .service-content a img {
    width: 30px;
    height: auto;
  }
  .section-title {
    font-size: 18px;
  }
  .feature-title {
    font-size: 16px;
  }
  .feature-description {
    font-size: 14px;
  }
}
</style>
